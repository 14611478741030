module.exports ={
  "propertyService": "https://fvnf1aq897.execute-api.us-east-1.amazonaws.com/test1",
  "domusoApiService": "https://h3u3ywrx01.execute-api.us-east-1.amazonaws.com/test1",
  "appService": "https://domusograilsweb-test1.devmuso.com",
  "reservationService": "https://pm94yfmg9e.execute-api.us-east-1.amazonaws.com/test1",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://pq4lo1c077.execute-api.us-east-1.amazonaws.com/test1",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-test1.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-test1.devmuso.com",
  "maintenanceUrl": "https://pq4lo1c077.execute-api.us-east-1.amazonaws.com/test1/maintenance",
  "env": "test1"
}